// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-discography-tsx": () => import("./../../src/pages/discography.tsx" /* webpackChunkName: "component---src-pages-discography-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-performances-tsx": () => import("./../../src/pages/performances.tsx" /* webpackChunkName: "component---src-pages-performances-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-songs-tsx": () => import("./../../src/pages/songs.tsx" /* webpackChunkName: "component---src-pages-songs-tsx" */),
  "component---src-templates-discography-tsx": () => import("./../../src/templates/discography.tsx" /* webpackChunkName: "component---src-templates-discography-tsx" */),
  "component---src-templates-performances-tsx": () => import("./../../src/templates/performances.tsx" /* webpackChunkName: "component---src-templates-performances-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */),
  "component---src-templates-songs-tsx": () => import("./../../src/templates/songs.tsx" /* webpackChunkName: "component---src-templates-songs-tsx" */)
}

